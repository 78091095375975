import Footer from "./Footer";
import MainReview from "./HomeComponents/Reviews/MainReview";

function ReviewsPage () {
    return(
        <>
            <MainReview/>
            <Footer/>
        </>
    )
}
export default ReviewsPage;